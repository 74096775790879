<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add a New Admin User</h3>
            </div>
          </div>

          <div class="card-body">
            <!--begin::Form-->
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                label="Email"
                :rules="emailRules"
                :readonly="editMode == true"
                required
              ></v-text-field>
              <v-text-field
                v-model="name"
                label="Name"
                :rules="[(v) => !!v || 'Name is required']"
                required
              ></v-text-field>
              <v-checkbox
                v-model="enableUserCreation"
                label="Enable Create User Access"
                color="success"
                hide-details
              ></v-checkbox>

              <div class="fv-plugins-message-container">
                <div class="fv-help-block errorMsg">
                  {{ error }}
                </div>
                <div
                  v-show="elementVisible"
                  class="fv-help-block successMsg"
                  v-if="successMsg != ''"
                >
                  {{ successMsg }}
                </div>
              </div>
              <v-btn
                :disabled="!valid || loading || isDisable"
                color="success"
                class="mr-4"
                @click="validate"
                :loading="loading"
              >
                {{ editMode ? "Update Admin User" : "Add Admin User" }}
              </v-btn>
            </v-form>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">All Admin Users</h3>
            </div>
          </div>
          <div class="card-body">
            <!--begin::Table-->

            <v-card-title>
              <div class="row search-bar">
                <div class="col-md-4">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </div>
            </v-card-title>
            <v-data-table :headers="headers" :items="allUsers" :search="search">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="loggedInUser != item.id"
                  small
                  class="mr-2"
                  @click="loadUser(item)"
                  >mdi-pencil</v-icon
                >
              </template>

              <template v-slot:[`item.delete`]="{ item }">
                <v-icon
                  v-if="loggedInUser != item.id"
                  small
                  class="mr-2"
                  @click="deleteUserConfirmation(item)"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>

            <!--end::Table-->
          </div>
        </div>
      </div>
    </div>
    <v-row justify="center">
      <v-dialog v-model="confirmDialog" max-width="300">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to <br />
            Delete this User?</v-card-title
          >

          <v-card-text> This process can not be undone. </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="confirmDialog = false">
              Cancel
            </v-btn>

            <v-btn color="green darken-1" text @click="callDeleteUser">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<style lang="scss" scoped>
.successMsg {
  color: #28a745;
}
.errorMsg {
  color: #dc3545;
}
.fv-help-block {
  padding-bottom: 10px;
  font-size: 15px;
}
.search-bar {
  justify-content: flex-end;
}
.loading-div {
  height: 65%;
  width: 100%;
  z-index: 1;
  // background: red;
  position: absolute;
  bottom: 60px;
  // opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, child, get, set, update } from "firebase/database";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const dbRef = ref(getDatabase());
const functions = getFunctions();
const auth = getAuth();
export default {
  data: () => ({
    confirmDialog: false,

    valid: true,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    email: "",
    name: "",
    enableUserCreation: false,
    error: "",
    successMsg: "",
    editMode: false,
    loading: false,
    elementVisible: true,
    search: "",
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Password", value: "password" },
      { text: "User Creation Access", value: "euc" },
      { text: "Edit", value: "actions" },
      { text: "Delete", value: "delete" },
    ],

    allUsers: [],
    selectedUserId: "",
    isDisable: false,
    loggedInUser: "",
  }),
  components: {},
  computed: {},
  mounted() {
    this.getAllUsers();
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: "Users", route: "add-user" },
    //   // { title: "Add User  " },
    // ]);
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.editMode = false;
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editMode) {
          this.editUser();
        } else {
          this.addUser();
        }
      }
    },

    async addUser() {
      const functions = getFunctions();
      // if (this.$refs.form.validate()) {
      this.loading = true;

      try {
        if (this.email !== "" && this.name !== "") {
          let userRecord = {
            email: this.email,
            name: this.name,
            enableusercreation: this.enableUserCreation,
            type: "admin",
          };

          // alert(userRecord.enableusercreation)

          let createUserAdmin = httpsCallable(functions, "createUserAdmin");
          createUserAdmin(userRecord)
            .then((result) => {
              // Read result of the Cloud Function.
              this.loading = false;
              console.log(result);
              if (result.data.code == 1 || result.data.code == 3) {
                this.error = result.data.response;
              } else if (result.data.code == 2) {
                this.getAllUsers();
                this.successMsg = result.data.response;
                setTimeout(() => (this.elementVisible = false), 2000);
                this.reset();
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        // this.loading = false;
        this.error = "";
      }
      // }
    },
    async getAllUsers() {
      let requests = [];
      get(child(dbRef, `users/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((child) => {
              if (child.val().type == "admin") {
                if (child.val().enableusercreation == true) {
                  requests.push({
                    ...child.val(),
                    id: child.key,
                    euc: "Enabled",
                  });
                } else {
                  requests.push({
                    ...child.val(),
                    id: child.key,
                    euc: "Disabled",
                  });
                }
              }
            });
            this.allUsers = requests;
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteUserConfirmation(user) {
      this.selectedUserId = user.id;
      this.confirmDialog = true;
    },
    async callDeleteUser() {
      let deleteUser = httpsCallable(functions, "deleteUser");
      await deleteUser({ uid: this.selectedUserId })
        .then((response) => {
          // Read result of the Cloud Function.

          console.log(response.data);
          const db = getDatabase();
          set(ref(db, "users/" + this.selectedUserId), null);
          this.getAllUsers();
          this.confirmDialog = false;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    loadUser(user) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // console.log(user)
      this.editMode = true;
      this.selectedUserId = user.id;
      this.name = user.name;
      this.email = user.email;
      this.enableUserCreation = user.enableusercreation;
    },
    editUser() {
      this.loading = true;
      const db = getDatabase();
      update(ref(db, "users/" + this.selectedUserId), {
        name: this.name,
        enableusercreation: this.enableUserCreation,
        email: this.email,
      })
        .then(() => {
          this.getAllUsers();
          this.reset();
          this.loading = false;
          this.editMode = false;
          selectedUserId = "";
          this.loading = false;
        })
        .catch((error) => {
          // The write failed...
        });
    },
  },
  beforeMount() {
    this.getAllUsers();
  },
  created() {
    this.getAllUsers();
    this.loggedInUser = auth.currentUser.uid;
  },
};
</script>
